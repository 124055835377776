<template>
  <div>
    <a-modal width="80%" :destroyOnClose="true" v-model:visible="visible" :footer="null" >
      <template #title>
         <span style="font-weight:700"> {{ title }}</span>
      </template>
      <div>
        <repPbcNav @resetBtn="resetBtn" ref="repPbcNavRef" @searchAdd="searchAdd" :iptData = statisticsIpt></repPbcNav>
      </div>
      <div class="m-t2">
      <a-table  bordered  @change="changePage" :pagination="false" :loading="searchLoading" :columns="statisticsColumns" :data-source="statisticsList" :rowKey="(record, index) => { return index;}">
              <template #totalSettableAmt="{ record }" >
                <span style="color:red">{{ record.totalSettableAmt }}</span>
              </template>
      </a-table>
    </div>
   </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref,watch, } from 'vue';
import { singleStatistics } from '@/api/carrier/carrierList'
import repPbcNav from '../../repository/components/repPbcNav.vue';
import { getOrgTreeList } from "@/api/global";
import {transitionTimeYMD,compareTime } from '@/utils/util.js'
import { message } from 'ant-design-vue';
const props = defineProps({
  lookID:{
    type:String,
    default:''
  },
  title:{
  type:String,
  default:'标题'
  },
  timeID:{
    type:Number, 
  }
})
const searchLoading = ref(false)
const visible = ref(false)
const statisticsList = ref([]) 
const statisticsIpt = ref([
  {
    type: "select",
    placeholder: "任务段",
    value: null,
    prop: "taskMode",
    width: 150,
    opt: [
    {
      id: 1,
      name: "提"
    },
    {
      id: 2,
      name: "干"
    },
    {
      id: 3,
      name: "送"
    }
  ],
  },
  {
    type: "selectTime",
    placeholder: "卸车时间起",
    value: null,
    prop: "startTime",
    width: 200,
  },
  {
    type: "selectTime",
    placeholder: "卸车时间止",
    value: null,
    prop: "endTime",
    width: 200,
  },
  {
    type: "select",
    placeholder: "业务部门",
    value: null,
    prop: "business",
    width: 150,
    opt: [
      {
      id: 0,
      name: '华月'
      },
      {
      id: 1,
      name: '专线'
      },
    ]
  },
  {
    type: "select",
    placeholder: "调度部门",
    value: null,
    prop: "dispatch",
    width: 200,
    opt:[]
  },
  {
    type: "select",
    placeholder: "台量类型",
    value: null,
    prop: "type",
    width: 150,
    opt: [
      {
      id: 1,
      name: '散车'
      },
      {
      id: 2,
      name: '批量'
      },
    ]
  },
  {
    type: "select",
    placeholder: "全部",
    value: 1,
    prop: "timer",
    width: 150,
    opt: [
      {
      id: 1,
      name: '全部'
      },
      {
      id: 2,
      name: '一年'
      },
      {
      id: 3,
      name: '三个月'
      },
    ]
  },
])
const statisticsColumns = ref([
  {
    title: "任务段",
    dataIndex: "taskFlow",
    key: "taskFlow",
    align: "center",
  },
  {
    title: "承运台量",
    dataIndex: "totalNum",
    key: "totalNum",
    align: "center",
  },
  {
    title: "超时台量",
    dataIndex: "timeoutNum",
    key: "timeoutNum",
    align: "center",
  },
  {
    title: "准时率",
    dataIndex: "onTimeRate",
    key: "onTimeRate",
    align: "center",
  },
  {
    title: "总运费",
    dataIndex: "totalFreight",
    key: "totalFreight",
    align: "center",
  },
  {
    title: "未结算金额",
    dataIndex: "totalSettableAmt",
    key: "totalSettableAmt",
    align: "center",
    slots:{customRender:"totalSettableAmt"}
  },
]);
//搜索
const repPbcNavRef = ref(null)
const searchAdd = (val) => {
  if(compareTime(val.startTime,val.endTime)){
    message.warn('结束时间必须大于开始时间，请重新选择')
    repPbcNavRef.value.resetTime()
    return
  }
  timePeriodType.value = val.timer
  let arr = {
    vehicleNum:val.type,
    transportType:val.taskMode,
    orderBusiness:val.business,
    orgId:val.dispatch,
    startTime:transitionTimeYMD(val.startTime),
    endTime:transitionTimeYMD(val.endTime)

  }
  reqData(props.lookID,arr)
} 
//重置
const resetBtn = () => {
  statisticsIpt.value[6].value = 3
  timePeriodType.value = 3
  reqData(props.lookID)
}
//数据请求
const reqData = (id,val) => {
  searchLoading.value = true;
  let msg = {
    carrierId:id,
    timePeriodType:timePeriodType.value
  }
  Object.assign(msg,val)
  singleStatistics(msg).then(res => {
    searchLoading.value = false
   if(res.code !== 10000) return
   if( res.data.length > 0){
    res.data.forEach( item => {
      if(item.transportType.label){
        item.taskFlow = item.transportType.label
      }
      item.onTimeRate = item.onTimeRate + '%'
      item.totalFreight = '￥' + parseFloat(item.totalFreight).toFixed(2)
      item.totalSettableAmt = '￥' +  parseFloat(item.totalSettableAmt).toFixed(2)
   })
  }
   statisticsList.value = res.data
  })
}
const changePage = () => {
}
//获取部门
const getDepartment = () => {
  getOrgTreeList({}).then((res) => {
    if (res.code !== 10000) return;
    let treeList = res.data[0].treeList;
    statisticsIpt.value[4].opt = treeList;
  });
};

onMounted(() => {
  getDepartment()
})
const timePeriodType = ref(null)
watch(()=>visible.value,(val,oldVal)=> {
  if(val){
    timePeriodType.value = props.timeID
    statisticsIpt.value[6].value = props.timeID
    reqData(props.lookID)
  }
})


defineExpose({
    visible
});
</script>

<style lang="less" scoped>
:deep(.navBox){
padding: 0;
}
:deep(.ant-modal-title){
  font-weight: 700;
}
</style> 