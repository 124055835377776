<template>
  <div style="background:#fff;padding:10px">
    <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom:10px">

      <div style="font-size: 18px;color: #344563;font-weight: 700;">承运商统计</div>
      <a-form  layout="inline">
        <a-form-item label="">
          <a-select v-model:value="searchForm.periodType" style="width:150px" placeholder="承运商类型">
            <a-select-option :value="1">未审核</a-select-option>
            <a-select-option :value="2">新入期</a-select-option>
            <a-select-option :value="3">试用期</a-select-option>
            <a-select-option :value="4">合作期</a-select-option>
            <a-select-option :value="5">淘汰期</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.sortParam" style="width:150px" placeholder="按指定类型排名">
            <a-select-option :value="1">承运台量</a-select-option>
            <a-select-option :value="2">超时台量</a-select-option>
            <a-select-option :value="3">准时率</a-select-option>
            <a-select-option :value="4">总运费</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.timePeriodType"  style="width:100px"  placeholder="全部">
            <a-select-option :value="1">全部</a-select-option>
            <a-select-option :value="2">一年</a-select-option>
            <a-select-option :value="3">三个月</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.sort" style="width:150px" placeholder="降序">
            <a-select-option :value="1">降序</a-select-option>
            <a-select-option :value="2">升序</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.carrierName" placeholder="承运商名称" />
        </a-form-item>
        <a-form-item label="">
          <a-space>
            <a-button type="primary" @click="searchData" :loading="loading">搜索</a-button>
            <a-button @click="resetData" :loading="loading">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" :pagination="pagination"
      :loading="loading" :rowKey="(record, index) => { return index }" bordered @change="handleTableChange">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      
      <template #statistics="{ record }">
               <a @click="lookAdd(record)">查看</a>
      </template>

      <template #carrierName="{ record }">
        <div>
          <a @click="openDetail(record)">{{ record.carrierName }}</a>
        </div>
      </template>

      <template #timeoutNum="{ record }">
        <div>
          <a @click="openTimeout(record)" v-show="record.timeoutNum > 0">{{ record.timeoutNum }}</a>
          <span v-show="record.timeoutNum <= 0">{{ record.timeoutNum }}</span>
        </div>
      </template>

      <template #onTimeRate="{ record }">
        <div>
          {{ record.onTimeRate + '%' }}
        </div>
      </template>

      <template #totalSettableAmt="{ record }">
        <div>
          <span style="color:#f00">{{ record.totalSettableAmt }}</span>
        </div>
      </template>
    </a-table>
    <a-drawer width="80%" placement="right" v-model:visible="detailShow" destroyOnClose
      @close="detailShow = false, loadData()">
      <CarrierDetail :carrierId="carrierId" :detail="carrierData" type="1" />
    </a-drawer>
    <TimeOut ref="timeoutRef" :carrierId="carrierId" :carrierData="carrierData" />
    <Statistics :timeID="timeID" :lookID="lookID" :title="lookTitle" ref="statisticsRef"  />
  </div>

</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { carrierStatistics } from '@/api/carrier/carrierList'
import CarrierDetail from '@/views/carrier/toolDetail/index.vue'
import TimeOut from './timeOut.vue'
import Statistics from './statistics.vue'
export default {
  components: {
    TimeOut,
    CarrierDetail,
    Statistics
  },
  setup () {
    const lookID = ref(null)
    const timeoutRef = ref(null)
    const state = reactive({
      loading: false,
      detailShow: false,
      datadetailShow: false,
      carrierId: '',
      carrierData: {},
      listData: [],
      searchForm: {
        sort: null,
        sortParam: null,
        periodType: null,
        carrierName: '',
        timePeriodType:3
      },
      mirrorSearchForm: {},
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      columns: [
        {
          title: '排名',
          dataIndex: 'rankNum',
          align: 'center',
        },
        {
          title: '承运商名称',
          dataIndex: 'carrierName',
          align: 'center',
          slots: {
            customRender: 'carrierName'
          }
        },
        {
          title: '当前评分',
          dataIndex: 'levelScore',
          align: 'center'
        },
        {
          title: '所属周期',
          dataIndex: 'periodType.label',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '承运台量',
          dataIndex: 'totalNum',
          align: 'center'
        },
        {
          title: '超时台量',
          dataIndex: 'timeoutNum',
          align: 'center',
          slots: {
            customRender: 'timeoutNum'
          }
        },
        {
          title: '准时率',
          dataIndex: 'onTimeRate',
          align: 'center',
          slots: {
            customRender: 'onTimeRate'
          }
        },
        {
          title: '总运费',
          dataIndex: 'totalFreight',
          align: 'center'
        },
        {
          title: '未结算金额',
          dataIndex: 'totalSettableAmt',
          align: 'center',
          slots: {
            customRender: 'totalSettableAmt'
          }
        },
        {
          title: '统计',
          dataIndex: 'statistics',
          align: 'center',
          slots: {
            customRender: 'statistics'
          }
        },
      ]
    })
    onMounted(() => {
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    //查看
    const timeID = ref(3)
    const lookTitle = ref('')
    const statisticsRef = ref(null)
    const lookAdd = (item) => {
     
      lookID.value =  item.carrierId
      lookTitle.value = item.carrierName? item.carrierName + ' — 统计' :''
      let timer =  setTimeout(() =>{
        statisticsRef.value.visible = true
        clearTimeout(timer)
      },100) 
    }
    const loadData = () => {
      state.loading = true
      carrierStatistics({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        state.listData = res.data.records
        state.pagination.total = res.data.total
      }).finally(() => {
        state.loading = false
      })
    }
    const openDetail = (record) => {
      state.carrierId = record.carrierId
      state.carrierData = record
      state.detailShow = true
    }
    const openTimeout = record => {
      state.carrierId = record.carrierId
      state.carrierData = record
      setTimeout(() => {
        timeoutRef.value.datadetailShow = true
        timeoutRef.value.loadData()
      }, 10)
    }
    const searchData = () => {
      timeID.value = state.searchForm.timePeriodType
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      searchData()
    }
    const handleTableChange = page => {
      state.pagination = page
      loadData()
    }

    return {
      ...toRefs(state),
      timeoutRef,
      loadData,
      searchData,
      resetData,
      openDetail,
      openTimeout,
      handleTableChange,
      statisticsRef,
      lookAdd,
      lookID,
      lookTitle,
      timeID
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>