<template>
  <a-modal v-model:visible="datadetailShow" width="70%" :title="title" footer="">
    <div>
      <div>
        <a-form layout="inline" :model="searchForm">
          <a-form-item label="">
            <!-- <a-cascader :options="$store.state.app.PClist" v-model:value="startData" :show-search="{ filter }" :changeOnSelect="true"
            @change="setStartAddress" placeholder="起点" allowClear /> -->
            <a-input v-model:value="searchForm.startAddress" placeholder="起点" allowClear />
          </a-form-item>
          <a-form-item label="">
            <!-- <a-cascader :options="$store.state.app.PClist" v-model:value="endData" :show-search="{ filter }" :changeOnSelect="true"
            @change="setEndAddress" placeholder="终点" allowClear /> -->
            <a-input v-model:value="searchForm.endAddress" placeholder="终点" allowClear />
          </a-form-item>
          <a-form-item label="">
            <a-select style="width: 100%" v-model:value="searchForm.vehicleNum" allowClear
              :getPopupContainer="e => e.parentNode" placeholder="全部">
              <a-select-option :value="1">散车</a-select-option>
              <a-select-option :value="2">批量车</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-select style="width: 100%" v-model:value="searchForm.transportDistance" allowClear
              :getPopupContainer="e => e.parentNode" placeholder="业务类型">
              <a-select-option :value="1">短驳</a-select-option>
              <a-select-option :value="2">同省</a-select-option>
              <a-select-option :value="3">跨省</a-select-option>
              <a-select-option :value="4">跨国</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-select style="width: 100%" v-model:value="searchForm.orgId" allowClear
              :getPopupContainer="e => e.parentNode" placeholder="业务部门">
              <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id"
                @click="orgChange(item.id)">{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-space>
              <a-button :loading="loading" type="primary" @click="searchData">
                查询
              </a-button>
              <a-button @click="resetData"> 重置 </a-button>
            </a-space>
          </a-form-item>
        </a-form>
        <a-table :columns="[sortColumn, ...columns]" :row-key="record => record.id" size="small" :data-source="listData"
          :pagination="pagination" :loading="loading" :rowKey="(record, index) => { return index }" bordered
          @change="handleTableChange">
          <template #sort="{ index }">
            {{ index + 1 }}
          </template>

          <template #brand="{ record }">
            <a @click="openDetail(record)">{{ record.vinNo }}</a> -<span>{{ record.brand + record.model }}</span>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal v-model:visible="detailShow" footer="" :getContainer="false" width="65%"
      :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">

      <template #title>
        <WindowDetail v-if="detailData.vinNo" :detailData="detailData" />
      </template>
      <div class="left-content" style="width: auto">
        <TransportModel ref="transportModelRef" :windowShow="detailShow" :data="detailData" :detailType="true"
          @statusShow="transportModelShowStatus" />
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
import { reactive, toRefs,ref, onMounted } from 'vue'
import { timeoutVehicle } from '@/api/dataCenter/monitoring'
import WindowDetail from '@/views/components/vinNo/windowDetail.vue'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'

export default {
  props: {
    carrierId: String,
    carrierData: Object
  },
  components: {
    WindowDetail,
    TransportModel
  },
  setup (props) {
    const transportModelRef = ref(null)
    const state = reactive({
      datadetailShow: false,
      detailShow: false,
      loading: false,
      title: '',
      listData: [],
      searchForm: {
        startAddress: '',
        endAddress: '',
        transportDistance: null,
        orgId: null,
        vehicleNum: null
      },
      detailData: {},
      mirrorSearchForm: {},
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '车牌/车型',
          dataIndex: 'brand',
          align: 'center',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId',
          align: 'center'
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          align: 'center'
        },
        {
          title: '起点',
          dataIndex: 'startAddress',
          align: 'center'
        },
        {
          title: '终点',
          dataIndex: 'endAddress',
          align: 'center'
        },
        {
          title: '运车开始时间',
          dataIndex: 'departureTime',
          align: 'center'
        },
        {
          title: '运车完成时间',
          dataIndex: 'unloadingTime',
          align: 'center'
        },
        {
          title: '台量类型',
          dataIndex: 'vehicleNum.label',
          align: 'center'
        },
        {
          title: '业务类型',
          dataIndex: 'transportDistance.label',
          align: 'center'
        },
        {
          title: '超时小时',
          dataIndex: 'timeoutHour',
          align: 'center'
        },
        {
          title: '超时任务段',
          dataIndex: 'transportType.label',
          align: 'center'
        },
      ]
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const loadData = () => {
      state.loading = true
      state.title = props.carrierData.carrierName + '—超时车辆'
      timeoutVehicle({
        ...state.searchForm,
        carrierId: props.carrierId,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      searchData()
    }
    const handleTableChange = page => {
      state.pagination = page
      loadData()
    }
    const orgChange = id => {
      state.searchForm.orgId = id
    }
    const openDetail = record =>{
      state.detailData = record
      state.detailShow = true
      setTimeout(() => {
        transportModelRef.value.getTaskListData()
      }, 50)
    }
    const transportModelShowStatus = (e) => {
      if (e.orderId) state.detailData = e
    }

    return {
      ...toRefs(state),
      transportModelRef,
      loadData,
      orgChange,
      resetData,
      searchData,
      openDetail,
      handleTableChange,
      transportModelShowStatus
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-form-item) {
  width: 18%;
  margin: 5px;
}
</style>